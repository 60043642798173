.tpu__promotional__card {
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  /* margin: 16px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px !important;
  width: 96%;
  margin: auto;
}

.tpu__promotional__content {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 16px;
  /* background-color: yellow; */
}

.tpu__promotional__text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tpu__promotional__text h2 {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 8px !important;
  font-family: "Roboto", sans-serif !important;
}

.tpu__promotional__description {
  /* background-color: red; */
  width: 100%;
}

.tpu__promotional__description p {
  font-size: 13px;
  color: #666666;
  font-family: "Poppins", sans-serif !important;
}

.tpu__promotional__text img {
  width: 58px;
  height: 58px;
  border-radius: 24px;
  object-fit: cover;
}

.tpu__promotional__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1877f2;
  padding: 12px;
  border-radius: 8px;
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto", sans-serif !important;
  position: relative;
  transition: all 0.15s ease;
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tpu__promotional__button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.tpu__promotional__button.active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
}

.tpu__promotional__button span {
  margin-right: 8px;
}

.tpu__promotional__button svg {
  font-size: 18px;
}

.tpu__shareRefer__card {
  background: #ffffff;
  border-radius: 16px;
  padding: 18px;
  /* margin: 16px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px !important;
  width: 96%;
  margin: auto;
}

.tpu__shareLink__card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1877f2;
  padding: 12px;
  border-radius: 8px;
  margin: 18px 0 10px 10px !important;
  margin-right: 10px !important;
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto", sans-serif !important;
  position: relative;
  transition: all 0.15s ease;
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tpu__shareRefer__header {
  margin-bottom: 16px;
}

.tpu__shareRefer__header h2 {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  text-align: center;
  font-family: "Roboto", sans-serif !important;
}

.tpu__shareRefer__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tpu__shareRefer__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.tpu__shareRefer__button:hover {
  transform: translateY(-2px);
}

.tpu__shareRefer__icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}

.tpu__shareRefer__icon svg {
  width: 20px;
  height: 20px;
}

.tpu__shareRefer__button p {
  font-size: 10px;
  color: #666666;
  text-align: center;
  margin: 0;
}

.tpu__premium__section {
  margin-top: 20px;
  width: 96%;
  margin: 20px auto;
}

.tpu__premium__section__boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.tpu__premium__section__boxes .premium__box {
  flex: 1;
  min-width: 120px;
  text-align: center;
  padding: 10px 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.15s ease;
  position: relative;
  background-color: #ffffff;
}

.tpu__premium__section__boxes .premium__box:hover {
  transform: translateY(-2px);
  opacity: 0.9;
}

.tpu__premium__section__boxes .premium__box.active {
  transform: scale(0.95);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  opacity: 0.8;
}

.tpu__premium__section__boxes .premium__box span svg {
  color: #2f68fd;
  font-size: 25px;
}

.tpu__premium__section__boxes .premium__box p {
  margin-top: 10px !important;
  color: #000;
  font-size: 0.675rem;
  font-family: var(--font-family) !important;
  font-weight: 600 !important;
}

.tpu__connectWithUs__card {
  background: #ffffff;
  border-radius: 16px;
  padding: 13px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px !important;
  width: 96%;
  margin: auto;
}

.tpu__connectWithUs__header {
  margin-bottom: 16px;
}

.tpu__connectWithUs__header h2 {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  text-align: center;
}

.tpu__connectWithUs__buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tpu__connectWithUs__button {
  cursor: pointer;
  font-size: 28px;
}

.social-icon {
  width: 27px;
  height: 27px;
  transition: transform 0.2s;
}

.social-icon:hover {
  transform: scale(1.1);
}

.tpu__dashboard__top__buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  padding-bottom: 0px;
}

.tpu__dashboard__top__button {
  width: 32%;
  padding: 10px 0;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.15s ease;
}

.tpu__dashboard__top__button:hover {
  transform: translateY(-2px);
  opacity: 0.8;
}

.tpu__dashboard__top__button:active {
  transform: scale(0.95);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  opacity: 0.6;
}

.tpu__dashboard__top__content {
  padding: 0 10px;
}

.tpu__dashboard__top__content .label {
  color: #757575;
  font-size: 12px;
  margin: 0;
}

.tpu__dashboard__top__content .value__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.tpu__dashboard__top__content .value {
  font-size: 13px;
  font-weight: 600 !important;
  margin: 0;
}

.stake__card {
  background: linear-gradient(135deg, #0056d2 0%, #1877f2 100%);
  border-radius: 16px;
  padding: 0px 14px 10px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  margin-top: 20px !important;
  width: 96%;
  margin: auto;
}

.stake__card__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4px;
}

.stake__card__text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px; 
}

.stake__card__text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-bottom: 0px !important; 
  padding-bottom: 0px;           
}


.stake__card__text h2 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin: 0 !important;
  margin-bottom: 2px; 
  line-height: 1.15;
  font-family: "Roboto", sans-serif !important;
  padding-right: 15.5px;
}

.stake__card__icon {
  position: relative;
  top: 16px; 
  margin-left: 10px;
}

.stake__card__icon img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  object-fit: contain;
}


.stake__card__description {
  width: 100%;
}

.stake__card__description p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Poppins", sans-serif !important;
  margin: 2px 0 6px;
  line-height: 1.3;
  padding-right: 40px !important;
}

/* .stake__card__text img {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  object-fit: cover;
  margin-left: auto;
} */

.stake__card__button {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  background-color: #fff;
  padding: 5px 16px; 
  border-radius: 7px;
  text-decoration: none;
  color: #1877f2;
  font-size: 13px; 
  font-weight: 600;
  font-family: "Roboto", sans-serif !important;
  position: relative;
  transition: all 0.15s ease;
  transform: translateY(0);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 50%; 
}

/* New tag styles */
.stake__card__button .new-tag {
  position: absolute;
  top: 1px;
  right: -5px;
  background-color: #fff;
  color: #1877f2;
  padding: 2.5px 3px;
  border-radius: 4px;
  font-size: 7px;
  font-weight: 600;
  border: 1px solid #0056d2;
}

.stake__card__button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.stake__card__button.active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
}

.stake__card__button span {
  margin-right: 8px;
}

.stake__card__button svg {
  font-size: 18px;
}

.trade__card {
  background: linear-gradient(135deg, #0056d2 0%, #1877f2 100%);
  border-radius: 16px;
  padding: 12px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  margin-top: 20px !important;
  width: 96%;
  margin: auto;
}
.trade__card__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
}
.trade__card__text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.trade__card__text h2 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 4px !important;
  font-family: "Roboto", sans-serif !important;
  padding-right: 20px;
}

.trade__card__description {
  width: 100%;
}

.trade__card__description p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Poppins", sans-serif !important;
  margin-top: 4px;
  margin-bottom: 12px;
  padding-right: 30px !important;
}

.trade__card__text img {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  object-fit: cover;
  margin-left: auto;
}

.trade__card__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 10px 50px;
  border-radius: 8px;
  text-decoration: none;
  color: #1877f2;
  font-size: 15px;
  font-weight: 600;
  font-family: "Roboto", sans-serif !important;
  position: relative;
  transition: all 0.15s ease;
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: fit-content;
}
.trade__card__button .new-tag {
  position: absolute;
  top: 2px;
  right: -4px;
  background-color: #fff;
  color: #1877f2;
  padding: 3px 6px;
  border-radius: 7px;
  font-size: 10px;
  font-weight: 600;
  border: 1px solid #0056d2;
}
.trade__card__button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.trade__card__button.active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
}

.trade__card__button span {
  margin-right: 8px;
}

.trade__card__button svg {
  font-size: 18px;
}

.file-container {
  max-height: 300px; /* Set max height */
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  border: 2px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Custom Scrollbar */
.file-container::-webkit-scrollbar {
  width: 8px;
}

.file-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.file-container::-webkit-scrollbar-thumb {
  background: #3797f0; /* Blue */
  border-radius: 10px;
}

.file-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.file-preview:hover {
  transform: scale(1.03);
}

.preview-image {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 5px;
}

.file-info {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
}

.delete-btn {
  background: red;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.delete-btn:hover {
  background: darkred;
}

/* Referral Card Styles */
.tpu__referral__card {
  background: linear-gradient(135deg, #e6ecff 0%, #d2ddff 100%);
  border-radius: 10px 16px;
  padding: 16px;
  margin-top: 20px !important;
  width: 96%;
  margin: auto;
  box-shadow: 0 6px 10px rgba(74, 111, 255, 0.15);
  border: 1px solid #e0e8ff;
  margin-bottom: 14px;
}

/* Modern 3D card effect */
.premium-golden-card {
  background: linear-gradient(135deg, #fff8dc, #fce8b2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 rgba(255, 255, 255, 0.4);
  transition: all 0.2s ease-in-out;
  border-radius: 14px;
}

/* On hover, elevate card */
.premium-golden-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 2px 6px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 rgba(255, 255, 255, 0.4);
}

/* Icon container gets a glassy 3D finish */
.premium-golden-card .tpu__referral__iconContainer {
  box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.3),
    0 2px 4px rgba(0, 0, 0, 0.15);
}

/* .tpu__referral__content {
  margin-bottom: 14px;
} */

.tpu__referral__header {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.tpu__referral__iconContainer {
  width: 34px;
  height: 34px;
  border-radius: 10px;
  background-color: #4a6fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.tpu__referral__label {
  font-size: 11px;
  font-weight: 700 !important;
  color: #4a6fff;
  letter-spacing: 0.5px;
}

.tpu__referral__title {
  font-size: 15px;
  font-weight: 700 !important;
  color: #2a2a2a;
  margin-bottom: 6px !important;
  line-height: 22px;
}

.tpu__referral__descriptionText {
  font-size: 13px;
  color: #5e5e5e;
  line-height: 18px;
  margin-bottom: 0;
}

.tpu__referral__buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tpu__referral__primaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4a6fff;
  padding: 10px 16px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(74, 111, 255, 0.2);
  border: none;
  cursor: pointer;
  flex: 2;
  margin-right: 10px;
  transition: all 0.2s ease;
}

.tpu__referral__primaryButton:hover {
  background-color: #3a5fee;
  transform: translateY(-2px);
}

.tpu__referral__primaryButton:active {
  transform: translateY(1px);
}

.tpu__referral__primaryButton span {
  color: #ffffff;
  font-size: 13px;
  font-weight: 600 !important;
  margin-right: 6px;
}

.tpu__referral__secondaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8efff;
  padding: 10px 13px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  flex: 1;
  transition: all 0.2s ease;
}

.tpu__referral__secondaryButton:hover {
  background-color: #d8e5ff;
}

.tpu__referral__secondaryButton span {
  color: #4a6fff;
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
  text-wrap: nowrap !important;
}

.premium-button-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
}

.premium-primary-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #4a6fff;
  padding: 7px 20px;
  border-radius: 7px;
  box-shadow: 0 3px 6px rgba(74, 111, 255, 0.2);
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 50%;
}

.premium-primary-button:hover {
  background-color: #3a5fee;
  transform: translateY(-2px);
}

.premium-primary-button span {
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  margin-right: 6px;
}

.premium-membership-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.premium-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 8px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: white;
  /* transition: all 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  width: fit-content;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.1),
    inset 0 1px 0 rgba(255, 255, 255, 0.2);
  transition: all 0.2s ease;
  transform: translateY(0);
}

.premium-btn-icon {
  font-size: 16px;
}

.premium-btn-basic {
  background-color: #4caf50;
}

.premium-btn-plus {
  background-color: #e0aa3e;
  font-size: 10.5px;
}

/* Hover makes it pop */
.premium-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
              inset 0 -1px 0 rgba(0, 0, 0, 0.1),
              inset 0 1px 0 rgba(255, 255, 255, 0.25);
}

/* Active (click) makes it sink */
.premium-btn:active {
  transform: translateY(1px);
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2);
}

/* PremiumBox Component Styles */
.premium__box {
  position: relative;
}

.discount-tag-container {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff3333;
  padding: 4px 6px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.discount-tag-text {
  color: white;
  font-size: 8px;
  font-weight: bold !important;
  margin-right: 4px;
}

.pulsing-dot {
  width: 7px;
  height: 7px;
  border-radius: 4px;
  background-color: #ffffff;
  margin-left: 2px;
  animation: pulse 2s infinite;
}
